<template>
  <div>
    <base-header type="gradient-blue" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Visitas del mes"
            type="gradient-orange"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0"
          >
            <template slot="value">
              <span class="h1 font-weight-bold mb-0">
                {{statistics.visits.thisMonth}}
              </span>
            </template>
            <template slot="footer">
              <span v-if="statistics.visits.pastMonth < statistics.visits.thisMonth" class="text-success mr-2 d-flex"
                ><i class="fa fa-arrow-up"></i> 
                {{(100 - ((100 / statistics.visits.pastMonth) * statistics.visits.thisMonth)).toFixed(2)}}%
              </span>
              <span v-else class="text-danger mr-2 d-flex"
                ><i class="fa fa-arrow-down"></i> 
                {{(100 - ((100 / statistics.visits.pastMonth) * statistics.visits.thisMonth)).toFixed(2)}}%
              </span>
              <span class="text-nowrap">Respecto al mes pasado</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Número de ventas"
            type="gradient-red"
            sub-title="10"
            icon="ni ni-active-40"
            class="mb-4 mb-xl-0"
          >
            <template slot="value">
              <span class="h1 font-weight-bold mb-0">
                {{orders.thisMonth.length}}
              </span>
            </template>
            <template slot="footer">
              <span v-if="orders.pastMonth.length < orders.thisMonth.length" class="text-success mr-2 d-flex"
                ><i class="fa fa-arrow-up"></i> 
                {{(100 - ((100 / orders.pastMonth.length) * orders.thisMonth.length)).toFixed(2)}}%
              </span>
              <span v-else class="text-danger mr-2 d-flex"
                ><i class="fa fa-arrow-down"></i> 
                {{(100 - ((100 / orders.pastMonth.length) * orders.thisMonth.length)).toFixed(2)}}%
              </span>
              <span class="text-nowrap">Respecto al mes pasado</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Ventas del mes"
            type="gradient-green"
            icon="ni ni-money-coins"
            class="mb-4 mb-xl-0"
          >
            <template slot="value">
              <span class="h1 font-weight-bold mb-0">
                <money-format :value="totalInOrdersThisMonth" 
                  :locale="'en'"
                  :subunits-value="false" 
                  :hide-subunits="false">
                </money-format>
              </span>
            </template>
            <template slot="footer">
              <span v-if="totalInOrdersPastMonth < totalInOrdersThisMonth" class="text-success mr-2 d-flex">
                <i class="fa fa-arrow-up"></i> 
                {{ (100 - ((100 / totalInOrdersPastMonth) * totalInOrdersThisMonth)).toFixed(2)  }}%
              </span>
              <span v-else class="text-danger mr-2 d-flex">
                <i class="fa fa-arrow-down"></i> 
                {{ (100 - ((100 / totalInOrdersPastMonth) * totalInOrdersThisMonth)).toFixed(2)  }}%
              </span>
              <span class="text-nowrap">Respecto al mes pasado</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Cobros del mes"
            type="gradient-info"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
            <template slot="value">
              <span class="h1 font-weight-bold mb-0">
                <money-format :value="totalInPaymentsThisMonth" 
                  :locale="'en'"
                  :subunits-value="false" 
                  :hide-subunits="false">
                </money-format>
              </span>
            </template>
            <template slot="footer">
              <span v-if="totalInPaymentsPastMonth < totalInPaymentsThisMonth" class="text-success mr-2 d-flex">
                <i class="fa fa-arrow-up"></i> {{ (100 - ((100 / totalInPaymentsPastMonth) * totalInPaymentsThisMonth)).toFixed(2)  }}%
              </span>
              <span v-else class="text-danger mr-2 d-flex">
                <i class="fa fa-arrow-down"></i> {{ (100 - ((100 / totalInPaymentsPastMonth) * totalInPaymentsThisMonth)).toFixed(2)  }}%
              </span>
              <span class="text-nowrap">Respecto al mes pasado</span>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <card type="default" header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col-3">
                <h6 class="text-light text-uppercase ls-1 mb-1">Resumen</h6>
                <h5 class="h3 text-white mb-0">Ventas</h5>
              </div>
              <div class="col-5">
                <base-select @change="refreshSalesChart" :options="agents" :required="true" placeholder='Filtrar' />
              </div>
              <div class="col-4">
                <ul class="nav nav-pills justify-content-end">
                  <li class="nav-item mr-2 mr-md-0">
                    <a
                      class="active nav-link py-2 px-3"
                      href="#"                      
                      @click.prevent="initBigChart(6)"
                    >
                      <span class="d-none d-md-block">6 Meses</span>
                      <span class="d-md-none">M</span>
                    </a>
                  </li>
                  <li class="nav-item mr-2 mr-md-0">
                    <a
                      class="active nav-link py-2 px-3"
                      href="#"                      
                      @click.prevent="initBigChart(12)"
                    >
                      <span class="d-none d-md-block">12 Meses</span>
                      <span class="d-md-none">M</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <line-chart
              :height="350"
              ref="bigChart"
              :chart-data="bigLineChart.chartData"
              :extra-options="bigLineChart.extraOptions"
            >
            </line-chart>
          </card>
        </div>
        <!--
        <div class="col-xl-4">
          <card header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-uppercase text-muted ls-1 mb-1">Rendimiento</h6>
                <h5 class="h3 mb-0">Pedidos por mes</h5>
              </div>
            </div>

            <bar-chart
              :height="350"
              ref="barChart"
              :chart-data="redBarChart.chartData"
            >
            </bar-chart>
          </card>
        </div>
        -->
      </div>
      <!-- End charts-->

      <!--Tables  Agents Ranking
            <div class="row mt-5">
                <div class="col-xl-8 mb-5 mb-xl-0">
                    <page-visits-table></page-visits-table>
                </div>
                <div class="col-xl-4">
                    <social-traffic-table></social-traffic-table>
                </div>
            </div>
            End tables-->
    </div>
  </div>
</template>
<script>
// Charts
import * as chartConfigs from "@/components/Charts/config";
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";
import BaseSelect from '@/components/BaseSelect';
// Tables
import PageVisitsTable from "./Dashboard/PageVisitsTable";

import httpMixin from "../mixins/httpMixin";
import MoneyFormat from 'vue-money-format'

export default {
  components: {
    LineChart,
    BarChart,
    PageVisitsTable,
    BaseSelect,
    'money-format': MoneyFormat
  },
  data() {
    return {
      bigLineChart: {
        allData: [
          [0, 0, 0, 0, 0, 0],
        ],
        activeIndex: 0,
        chartData: {
          datasets: [],
          labels: [],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      redBarChart: {
        chartData: {
          labels: ["Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
          datasets: [
            {
              label: "Sales",
              data: [25, 20, 30, 22, 17, 29],
            },
          ],
        },
      },
      statistics: {
        visits: {
          thisMonth: 0,
          pastMonth: 0,
        }
      },
      agents:[],
      selectedAgent: null,
      orders: {
          thisMonth: [],
          pastMonth: [],
        },
      payments: {
        thisMonth: [],
        pastMonth: [],
      },
    };
  },
  methods: {
    initBigChart(months) {
      this.httpGet(`/dashboard/sales?agent=${this.selectedAgent ? this.selectedAgent.id : 0 }&months=${months}`)
        .then(res => {
          let chartData = {
            datasets: [
              {
                label: "Ventas",
                data: res.data.values,
              },
            ],
            labels: res.data.labels,
          };
          this.bigLineChart.chartData = chartData;
        })
        .catch(err => {

      })      
    },
    fetchStatistics: function () {
      this.httpGet(`/dashboard/stats`)
        .then((res) => {
          this.statistics.visits = res.data.visits
          this.orders.thisMonth = res.data.orders.thisMonth.map(order => {
            return JSON.parse(order.body)
          })
          this.orders.pastMonth = res.data.orders.pastMonth.map(order => {
            return JSON.parse(order.body)
          })
          this.payments.thisMonth = res.data.payments.thisMonth.map(payment => {
            return JSON.parse(payment.body)
          })
          this.payments.pastMonth = res.data.payments.pastMonth.map(payment => {
            return JSON.parse(payment.body)
          })
        })
        .catch((err) => {

        })
    },
    fetchAgents: function () {
      this.httpGet(`/agents`)
        .then(res => {
          this.agents = [{id: 0, name: "Todos los agentes"}].concat(res.data)
        })
        .catch(err => {

        })
    },
    refreshSalesChart: function (agent) {
      this.selectedAgent = agent
      this.initBigChart(6)
    }
  },
  mounted() {
    this.initBigChart(6)
    this.fetchStatistics()
    this.fetchAgents()
  },
  computed: {
    totalInOrdersThisMonth: function() {
      return this.orders.thisMonth.reduce((total, order) => {
        return total += order.lines.reduce((totalLines, line) => {
          return totalLines += (line.precioNetoImp * line.unidades)
        }, 0)
      }, 0)
    },
    totalInOrdersPastMonth: function() {
      return this.orders.pastMonth.reduce((total, order) => {
        return total += order.lines.reduce((totalLines, line) => {
          return totalLines += (line.precioNetoImp * line.unidades)
        }, 0)
      }, 0)
    },
    totalInPaymentsThisMonth: function() {
      return this.payments.thisMonth.reduce((total, payment) => {
        return total += payment.importe
      }, 0)
    },
    totalInPaymentsPastMonth: function() {
      return this.payments.pastMonth.reduce((total, payment) => {
        return total += payment.importe
      }, 0)
    }
  },
  mixins: [
    httpMixin,
  ],
};
</script>
<style></style>
