<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            Ingresa con tus crendenciales
          </div>
          <div v-if="error != ''" class="text-center mb-4">
            <p class="text-danger">{{error}}</p>
          </div>
          <form role="form">
            <base-input
              class="input-group-alternative mb-3"
              placeholder="Correo"
              addon-left-icon="ni ni-email-83"
              v-model="model.email"
              @keyup.enter="singIn"
            >
            </base-input>

            <base-input
              class="input-group-alternative"
              placeholder="Contraseña"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.password"
              @keyup.enter="singIn"
            >
            </base-input>
            <div class="text-center">
              <base-button type="primary" class="my-4" v-on:click="singIn"
                >Iniciar sesión</base-button
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import httpMixin from "../mixins/httpMixin";
export default {
  name: "login",
  data() {
    return {
      model: {
        email: "",
        reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        password: "",
      },
      error: '',
    };
  },
  methods: {
    singIn: async function () {
      const isValidEmail = this.isValidEmail();
      const isValidPassword = this.isValidPassword();
      if (isValidEmail == true) {
        if (isValidPassword == true) {
          const data = {
            email: this.model.email,
            password: this.model.password,
          };
          const res = await this.httpPost("/server/login", data);
          if (res.status == "success") {
            await this.$store.commit("setToken", res.data.access_token);
            await this.$store.commit("setUserInfo", res.data.user);
            await this.$store.commit("setAccountInfo", res.data.account);
            await this.$store.commit("setPusherInfo", res.data.pusher);
            this.$router.push({ path: "/dashboard" });
          } else {
            this.showError(res.message);
          }
        } else {
          this.showError(isValidPassword);
        }
      } else {
        this.showError(isValidEmail);
      }
    },
    isValidEmail() {
      if (this.model.email == null || this.model.email == "") {
        return "Ingresa tu correo electrónico";
      } else if (!this.model.reg.test(this.model.email)) {
        return "Ingresa un correo electrónico válido";
      } else {
        return true;
      }
    },
    isValidPassword() {
      if (this.model.password == null || this.model.password == "") {
        return "Ingresa tu contraseña";
      } else if (this.model.password.length < 8) {
        return "La contraseña debe tener más de 7 caracteres";
      } else {
        return true;
      }
    },
    showError(error) {
      this.error = error;
      setTimeout(function() {
        this.error = '';
        console.log('yes')
      }.bind(this), 3000);
    }
  },
  mixins: [httpMixin],
};
</script>
<style>
</style>
