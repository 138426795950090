<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col-lg-3">
          <h3>Folio: {{ folio }}</h3>
        </div>
        <div class="col-lg-3">
          <h3 :class="`text-${convertStatusToColor(status)}`">
            {{
              status === 'S'
                ? 'Guardado'
                : status === 'R'
                ? 'Pendiente de guardar'
                : 'Error al guardar'
            }}
          </h3>
        </div>
        <div class="col-lg-3">
          <h3>Partidas: {{ lines.length }}</h3>
        </div>
        <div class="col-lg-3">
          <h3 class="d-inline-flex">
            Total: &nbsp;
            <money-format
              :value="totalInOrder"
              :locale="'en'"
              :subunits-value="false"
              :hide-subunits="false"
            >
            </money-format>
          </h3>
        </div>
      </div>
    </div>
    <div v-if="status === 'E'" class="row align-items-left">
      <div class="col-md-12">
        <p>{{ serverResponse }}</p>
      </div>
      <div class="col-md-12">
        <base-button v-on:click="retryTransaction" type="primary"
          >Reintentar</base-button
        >
      </div>
    </div>
    <div class="table-responsive" style="min-height: 200px">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="rows"
      >
        <template slot="columns">
          <th>Clave</th>
          <th>Nombre</th>
          <th>Unidades</th>
          <th>Precio Neto</th>
          <th>Total</th>
        </template>

        <template slot-scope="{ row }">
          <th scope="row">
            <span class="name mb-0 text-sm">{{ row.articuloClave }}</span>
          </th>
          <td class="budget">
            {{ row.articuloNombre }}
          </td>
          <td>
            {{ row.unidades }}
          </td>
          <td class="">
            <money-format
              :value="row.precioNetoImp"
              :locale="'en'"
              :subunits-value="false"
              :hide-subunits="false"
            >
            </money-format>
          </td>
          <td class="completation">
            <money-format
              :value="row.precioNetoImp * row.unidades"
              :locale="'en'"
              :subunits-value="false"
              :hide-subunits="false"
            >
            </money-format>
          </td>
        </template>
      </base-table>
    </div>
    <!--
    <div class="card-footer d-flex justify-content-end"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination :total="30"></base-pagination>
    </div>
  --></div>
</template>
<script>
import MoneyFormat from 'vue-money-format';
import httpMixin from '../../mixins/httpMixin';
export default {
  name: 'orders-table',
  props: {
    type: {
      type: String,
    },
    id: Number,
    title: String,
    lines: Array,
    folio: String,
    status: String,
    serverResponse: String,
  },
  data() {
    return {
      rows: [],
    };
  },
  mounted() {
    this.rows = this.lines;
  },
  methods: {
    convertStatusToColor(status) {
      if (status == 'S') {
        return 'success';
      } else if (status == 'R') {
        return 'warning';
      } else if (status == 'E') {
        return 'danger';
      }
    },
    retryTransaction() {
      this.httpPut('/transactions', {
        id: this.id,
        status: 'R',
        response: null,
      })
        .then((res) => {
          if (res.status === 'success') this.status = 'R';
        })
        .catch((err) => {
          this.serverResponse = err
        });
    },
  },
  computed: {
    totalInOrder() {
      let total = 0.0;
      this.lines.forEach(async (line) => {
        total = total + line.unidades * line.precioNetoImp;
      });
      return total;
    },
  },
  components: {
    'money-format': MoneyFormat,
  },
  mixins: [httpMixin],
};
</script>
<style></style>
