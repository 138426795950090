<template>
  <div>
    <div class="card shadow mb-3" :class="type === 'dark' ? 'bg-default' : ''">
      <div
        class="card-header border-0"
        :class="type === 'dark' ? 'bg-transparent' : ''"
      >
        <div class="row align-items-center">
          <div class="col-auto">
            <base-input
              :required="false"
              alternative=""
              label="Folio"
              placeholder="Folio"
              input-classes="form-control"
              v-model="search.folio"
            />
          </div>
          <div class="col-auto">
            <div class="form-group has-label">
              <label class="form-control-label"> Agente </label>
              <select
                name="agent"
                class="form-control"
                v-model="search.agent"
                aria-placeholder="Agente"
              >
                <option value="" disabled>Agente</option>
                <option
                  v-for="agent in agents"
                  v-bind:key="agent.id"
                  :value="agent"
                >
                  {{ agent.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-auto">
            <base-input
              :required="false"
              alternative=""
              label="Cliente"
              placeholder="Cliente"
              input-classes="form-control"
              v-model="search.client"
            />
          </div>
          <div class="col-auto">
            <div class="form-group has-label">
              <label class="form-control-label"> Tipo </label>
              <select
                name="type"
                class="form-control"
                v-model="search.type"
                aria-placeholder="Tipo"
              >
                <option value="" disabled>Tipo</option>
                <option
                  v-for="type in types"
                  v-bind:key="type.key"
                  :value="type.value"
                >
                  {{ type.key }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-auto">
            <div class="form-group has-label">
              <label class="form-control-label"> Estatus </label>
              <select
                name="status"
                class="form-control"
                v-model="search.status"
              >
                <option value="" disabled>Estatus</option>
                <option v-for="s in status" v-bind:key="s.key" :value="s.value">
                  {{ s.key }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-auto">
            <base-input
              :required="false"
              alternative=""
              label="Fecha"
              type="date"
              placeholder="Elige la fecha"
              input-classes="form-control"
              v-model="search.date"
            />
          </div>
          <div class="col-auto text-right">
            <base-button type="danger" v-on:click="clearFields">
              <i class="fas fa-times"></i>
            </base-button>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col">
            <base-button type="primary" v-on:click="fetchTransactionsInfo">
              Buscar &zwnj;
              <i class="fas fa-search"></i>
            </base-button>
          </div>
        </div>
      </div>
    </div>

    <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
      <div
        class="card-header border-0"
        :class="type === 'dark' ? 'bg-transparent' : ''"
      >
        <div class="row align-items-center mb-3">
          <div class="col">
            <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
              {{ title }} | {{ total }}
            </h3>
          </div>
        </div>
        <div class="table-responsive">
          <base-table
            class="table align-items-center table-hover table-flush"
            :class="type === 'dark' ? 'table-dark' : ''"
            :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
            tbody-classes="list"
            :data="transactions"
          >
            <template slot="columns">
              <th>Folio</th>
              <th>Agente</th>
              <th>Cliente</th>
              <th>Tipo</th>
              <th>Estatus</th>
              <th>Fecha creado</th>
            </template>

            <template slot-scope="{ row }">
              <td>
                <router-link class="text-dark" :to="'/transaccion/' + row.id">
                  {{ row.body.folio }}
                </router-link>
              </td>
              <td>
                <router-link class="text-dark" :to="'/transaccion/' + row.id">
                  {{ row.agent_name }}
                </router-link>
              </td>
              <td>
                <router-link class="text-dark" :to="'/transaccion/' + row.id">
                  {{ row.client_name }}
                </router-link>
              </td>
              <td>
                <router-link class="text-dark" :to="'/transaccion/' + row.id">
                  {{ convertTypeToName(row.type) }}
                </router-link>
              </td>
              <td>
                <router-link class="text-dark" :to="'/transaccion/' + row.id">
                  <badge
                    class="badge-dot mr-4"
                    :type="convertStatusToColor(row.status)"
                  >
                    <i :class="`bg-${convertStatusToColor(row.status)}`"></i>
                    <span>{{ convertStatusToName(row.status) }}</span>
                  </badge>
                </router-link>
              </td>
              <td>
                <router-link class="text-dark" :to="'/transaccion/' + row.id">
                  {{ row.created_at | moment('DD/MM/YYYY hh:mm a') }}
                </router-link>
              </td>
            </template>
          </base-table>
        </div>
        <div
          class="card-footer d-flex justify-content-end"
          :class="type === 'dark' ? 'bg-transparent' : ''"
        >
          <base-pagination
            @input="changePage"
            :value="value"
            :pageCount="pageCount"
            :perPage="perPage"
          ></base-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import httpMixin from '../../mixins/httpMixin'
export default {
  name: 'transactions-table',
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      transactions: [],
      value: 1,
      perPage: 0,
      pageCount: 0,
      total: 0,
      search: {
        folio: '',
        agent: {id: 0},
        client: '',
        type: '',
        status: '',
        date: '',
      },
      agents: [],
      types: [
        { value: 'order', key: 'Venta' },
        { value: 'payment', key: 'Cobro' },
        { value: 'return', key: 'Devolución' },
      ],
      status: [
        { value: 'P', key: 'Pendiente' },
        { value: 'S', key: 'Guardado' },
        { value: 'E', key: 'Error' },
      ],
    }
  },
  mounted() {
    this.fetchTransactionsInfo(), this.fetchAgentsInfo()
  },
  methods: {
    fetchTransactionsInfo: function () {
      this.httpGet(
        `/transactions/report?
            page=${this.value}&
            folio=${this.search.folio ?? ''}&
            agent=${this.search.agent.id ?? 0}&
            client=${this.search.client ?? ''}&
            type=${this.search.type ?? ''}&
            status=${this.search.status ?? ''}&
            date=${this.search.date ?? ''}&
            `
      )
        .then((res) => {
          this.transactions = res.data.data
          this.total = res.data.total
          this.perPage = res.data.per_page
          this.pageCount = res.data.last_page
          this.value = res.data.current_page;
        })
        .catch((err) => {
          console.log(err)
        })
    },
    fetchAgentsInfo: function () {
      this.httpGet('/agents').then((res) => {
        this.agents = res.data
      })
    },
    clearFields: function () {
      this.search = {
        folio: '',
        agent: '',
        client: '',
        type: '',
        status: '',
        date: '',
      }
      this.fetchTransactionsInfo()
    },
    changePage: function (value) {
      this.value = value
      this.fetchTransactionsInfo()
    },
    convertTypeToName: function (type) {
      if (type == 'order') {
        return 'Venta'
      } else if (type == 'payment') {
        return 'Cobro'
      } else if (type == 'return') {
        return 'Devolución'
      }
    },
    convertStatusToName: function (status) {
      if (status == 'P') {
        return 'Pendiente'
      } else if (status == 'S') {
        return 'Guardado'
      } else if (status == 'E') {
        return 'Error'
      } else if (status == 'R') {
        return 'Reintentado'
      } else {
        return 'Desconocido'
      }
    },
    convertStatusToColor: function (status) {
      if (status == 'P') {
        return 'warning'
      } else if (status == 'S') {
        return 'success'
      } else if (status == 'E') {
        return 'danger'
      } else if (status == 'R') {
        return 'info'
      } else {
        return 'secondary'
      }
    },
  },
  mixins: [httpMixin],
}
</script>
