<template>
  <div>
    <base-header type="gradient-blue" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-6">
                  <h3 class="mb-0">Información de la visita</h3>
                </div>
              </div>
            </div>
            <template>
                <div class="pl-lg-4">
                  <div class="row mb-3">
                    <div class="col-lg-2">
                      <label>Estatus de la visita</label>
                      <h4>
                        <badge class="badge-dot mr-4" :type="convertEffectiveToColor(visit.effective)">
                          <i :class="`bg-${convertEffectiveToColor(visit.effective)}`"></i>
                          <span class="" style="font-weight: bold;">{{convertEffectiveToName(visit.effective)}}</span>
                        </badge>
                      </h4>
                    </div>
                    <div class="col-lg-4">
                      <label v-if="visit.effective == 'N'" >Razón de inefectividad</label>
                      <h4 v-if="visit.effective == 'N'" >{{ visit.reason }}</h4>
                    </div>
                    <div class="col-lg-3">
                      <label>Hora de inicio</label>
                      <h4>{{ visit.start_at | moment("DD/MM/YYYY LT") }}</h4>
                    </div>
                    <div class="col-lg-3">
                      <label>Hora de finalización</label>
                      <h4>{{ visit.end_at | moment("DD/MM/YYYY LT") }}</h4>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <label>Cliente</label>
                      <h4>{{ visit.client_name }}</h4>
                    </div>
                    <div class="col-lg-3">
                      <label>Clave del clente</label>
                      <h4>{{ visit.client_key }}</h4>                      
                    </div>
                    <div class="col-lg-3">
                      <label>Ubicación</label>
                      <div class="media align-items-center">
                        <a :href="'http://maps.google.com/maps?q=' + visit.latitude + ',' + visit.longitude + '&z=18'" target="_blank" class="ni ni-building mr-3" style="font-size: 1.25rem;">
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <!-- Reasons -->
                <h6 class="heading-small text-muted mb-4">
                  Resumen
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-3">
                      <label>No. de pedidos:</label>
                      <h3>{{ countTransactionsType('order') }}</h3>
                    </div>
                    <div class="col-lg-3">
                      <label>No. de cobros:</label>
                      <h3>{{ countTransactionsType('payment') }}</h3>
                    </div>
                    <div class="col-lg-3">
                      <label>Total en pedidos:</label>
                      <h3>
                        <money-format :value="totalInOrders" 
                          :locale="'en'"
                          :subunits-value="false" 
                          :hide-subunits="false">
                        </money-format>
                      </h3>
                    </div>
                    <div class="col-lg-3">
                      <label>Total en cobros:</label>
                      <h3>
                        <money-format :value="totalInPayments" 
                          :locale="'en'"
                          :subunits-value="false" 
                          :hide-subunits="false">
                        </money-format>
                      </h3>
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <h5 class="heading mb-4">
                  Pedidos
                </h5>
                <div class="" v-for="order in orders" :key="order.id">
                  <div class="row mb-5">
                    <div class="col-12">
                        <orders-table title="Partidas" :lines="order.body.lines" :status="order.status" :folio="order.body.folio"></orders-table>
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <h5 class="heading mb-4">
                  Cobros
                </h5>
                <div class="" v-for="payment in payments" :key="payment.id">
                  <div class="row mb-5">
                    <div class="col-12">
                        <payments-table title="Cargos" :lines="payment.body.charges" :status="payment.status" :total="payment.body.importe" :folio="payment.body.folio"></payments-table>
                    </div>
                  </div>
                </div>
            </template>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <base-alert
            ref="alert"
            :type="alert.type"
            :dismissible="true"
            :icon="alert.icon"
          >
            <template slot="text">{{ alert.message }}</template>
          </base-alert>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import httpMixin from "../mixins/httpMixin"
import OrdersTable from './Tables/OrdersTable'
import PaymentsTable from './Tables/PaymentsTable'
import MoneyFormat from 'vue-money-format'
export default {
  name: "client-visit",
  data() {
    return {
      visitSecret: this.$route.params.secret,
      visit: {},
      transactions: [],
      orders: [],
      payments: [],
      totalOrders: 0.0,
      totalPayments: 0.0,
      alert: {
        type: "default",
        message: "",
        icon: "",
      },
    }
  },
  mounted() {
   this.fecthVisit()
  },
  methods: {
    fecthVisit: async function () {
      this.httpGet("/visit/" + this.visitSecret)
        .then((res) => {
          this.visit = res.data.visit;
          this.transactions = res.data.transactions;
          this.transactions.forEach(async (t) => {
            var body = JSON.parse(t.body);
            var transaction = {
              id: t.id,
              status: t.status,
              body: body
            }
            if(t.type == 'order') {            
              this.orders.push(transaction)
            } else if(t.type == 'payment') {              
              this.payments.push(transaction)
            }
          });
        })
        .catch((err) => {
          this.alert.type = "danger";
          this.alert.message = err.message;
          this.alert.icon = "ni ni-notification-70";
          this.$refs.alert.alertCreated();
        })
    },
    convertEffectiveToColor (effective) {
      if (effective == 'S') {
        return 'success'
      } else if (effective == 'N') {
        return 'danger'
      }
    },
    convertEffectiveToName (effective) {
      if (effective == 'S') {
        return 'Efectiva'
      } else if (effective == 'N') {
        return 'Inefectiva'
      }
    },
    countTransactionsType(type) {
      return this.transactions.filter(t => t.type == type).length;
    },    
  },
  mixins: [httpMixin],
  computed: {
    totalInOrders() {
      let total = 0.0;
      this.orders.forEach(async (t) => {                 
        t.body.lines.forEach(l => {
          total = total + (l.unidades * l.precioNetoImp)
        });
      });
      return total;
    },
    totalInPayments() {
      let total = 0.0;
      this.payments.forEach(async (t) => {
        total = total + t.body.importe
      });
      return total;
    }
  }, components: {
    OrdersTable,
    PaymentsTable,
    'money-format': MoneyFormat
  }
};
</script>
<style></style>
