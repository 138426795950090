<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            Filtrar
          </h3>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-3">
          <base-input
            :required="false"
            alternative=""
            label="Cliente"
            placeholder="Nombre del cliente"
            input-classes="form-control"
            v-model="search.client"
          />
        </div>
        <div class="col-3">
          <div class="form-group has-label">
            <label class="form-control-label">
              Agente
            </label>
            <select
              name="agent"
              class="form-control"
              v-model="search.agent"
            >
              <option
                v-for="agent in agents"
                v-bind:key="agent.id"
                :value="agent"
              >
                {{ agent.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-3">
          <base-input
            :required="false"
            alternative=""
            label="Fecha"
            type="date"
            placeholder="Elige la fecha"
            input-classes="form-control"
            v-model="search.date"
          />
        </div>
        <div class="col-3 text-right">
          <base-button type="primary" v-on:click="fetchVisitsInfo">Filtrar</base-button>
          <base-button type="danger" v-on:click="seeAll">Ver todo</base-button>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}} | {{total}}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive" style="min-height:250px;">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="visits">
        <template slot="columns">
          <th>Cliente</th>
          <th>Agente</th>
          <th>Efectiva</th>
          <th>Ubicación</th>
          <th>Fecha</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <a href="#" class="avatar rounded-circle mr-3">
                <img alt="Imagen usuario" src="img/theme/user-profile-default.jpg">
              </a>
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.client_name}}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{row.name}}
          </td>
          <td>
            <badge class="badge-dot mr-4" :type="convertEffectiveToColor(row.effective)">
              <i :class="`bg-${convertEffectiveToColor(row.effective)}`"></i>
              <span class="status">{{convertEffectiveToName(row.effective)}}</span>
            </badge>
          </td>
          <td class="text-right">
            <div class="media align-items-center">
              <a :href="'http://maps.google.com/maps?q=' + row.latitude + ',' + row.longitude + '&z=18'" target="_blank" class="ni ni-building mr-3" style="font-size: 1.25rem;">
              </a>
            </div>
          </td>
          <td class="completation">
              {{row.created_at | moment("DD/MM/YYYY hh:mm a")}}
          </td>
          <td class="text-right">
            <base-dropdown class="dropdown"
                          position="right">
              <a slot="title" class="btn btn-sm btn-icon-only text-light" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <router-link :to="{ path: '/visita/'+ row.id }" class="dropdown-item">
                  <span class="dropdown-item">Ver más</span>
                </router-link>
              </template>
            </base-dropdown>
          </td>
          
        </template>

      </base-table>
    </div>
  
    <div class="card-footer d-flex justify-content-end"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination @input="changePage" :value="value" :pageCount="pageCount" :perPage="perPage"></base-pagination>
    </div>
  
  </div>
</template>
<script>
import httpMixin from '../../mixins/httpMixin'
  export default {
    name: 'visits-table',
    props: {
      type: {
        type: String
      },
      title: String
    },
    data() {
      return {
        visits: [],
        value: 1,
        perPage: 0,
        pageCount: 0,
        total: 0,
        search: {
          client: '',
          agent: {
            id: 0,
          },
          date: ''
        },
        agents: {}
      }
    },
    mounted() {
      this.fetchVisitsInfo()
      this.fetchAgents()
    },
    methods: {
      fetchVisitsInfo: function () {
        this.httpGet(`/visits?page=${this.value}&client=${this.search.client ?? ''}&agent=${this.search.agent.id ?? 0}&date=${this.search.date}`)
          .then(res => {
            this.visits = res.data.data;
            this.perPage = res.data.per_page;
            this.pageCount = res.data.last_page;
            this.value = res.data.current_page;
            this.total = res.data.total;
          })
          .catch(err => {
            console.log(err)
          })
      },
      fetchAgents: function () {
        this.httpGet(`/agents`)
          .then(res => {
            this.agents = res.data;
          })
          .catch(err => {
            console.log(err)
          })
      },
      seeAll() {
        this.search = {
          client: '',
          agent: {
            id: 0,
          },
          date: ''
        };
        this.fetchVisitsInfo()
      },
      changePage(value) {
        this.value = value
        this.fetchVisitsInfo()
      },
      convertEffectiveToColor (effective) {
        if (effective == 'S') {
          return 'success'
        } else if (effective == 'N') {
          return 'danger'
        }
      },
      convertEffectiveToName (effective) {
        if (effective == 'S') {
          return 'Si'
        } else if (effective == 'N') {
          return 'No'
        }
      },
    },
    mixins: [httpMixin]
  }
</script>
<style>
</style>
