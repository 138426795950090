<style>
.base-select {
    height: 40px;
    border-radius: 10px;
    border: 1px solid #eee;
    background-color: #172b4d;
    color: white;
}
</style>
<template>
    <select v-on:change="updateValue" :options="options" v-model="selected" :required="required"  class="base-select w-100 pl-3">
        <option v-for="option in options" :value="option" :key="option.id">
            {{ option.name }}
        </option>
    </select>
</template>
<script>
export default {
    name: 'base-select',
    props: {
        defaultValue: {
            type: Object,
            default: () => {}
        },
        modelValue: {
            type: Object,
            default: null
        },
        options: {
            type: Array,
            default: () => []
        },
        required: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            selected: {}
        }
    },
    watch: {
        defaultValue: async function(val) {
            this.selected = val
            this.updateValue() 
        }
    },
    methods: {
        updateValue() {
            this.$emit('change', this.selected)
        }
    }
}
</script>