<template>
  <div>
    <base-header type="gradient-blue" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Configuración General</h3>
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">Configuración</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-4">
                      <label>Lista de precios</label>
                      <select
                        name="select"
                        class="form-control"
                        v-model="account.pricingList"
                      >
                        <option
                          v-for="list in priceLists"
                          v-bind:key="list['ID']"
                          :value="list['ID']"
                        >
                          {{ list["NOMBRE"] }}
                        </option>
                      </select>
                    </div>
                    <div class="col-lg-4">
                      <label>Sincronizar cada:</label>
                      <select
                        name="select"
                        class="form-control"
                        v-model="account.serverTimer"
                      >
                        <option
                          v-for="(schedule, index) in schedules"
                          v-bind:key="index"
                          :value="schedule.value"
                        >
                          {{ schedule.description }}
                        </option>
                      </select>
                    </div>
                    <div class="col-lg-4">
                      <label>Forma de pago de devoluciones:</label>
                      <select
                        name="select"
                        class="form-control"
                        v-model="account.return_payment_method"
                      >
                        <option
                          v-for="(method, index) in paymentMethods"
                          v-bind:key="index"
                          :value="method['ID']"
                        >
                          {{ method['NM'] }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <hr/>
                  <div class="row mt-3">
                    <div class="col-lg-4">
                      <label>Condición de pago nuevos clientes</label>
                      <select
                        name="select"
                        class="form-control"
                        v-model="account.clients_payment_condition"
                      >
                        <option
                          v-for="(condition, index) in paymentConditions"
                          v-bind:key="index"
                          :value="condition['ID']"
                        >
                          {{ condition['NOMBRE'] }}
                        </option>
                      </select>
                    </div>
                    <div class="col-lg-4">
                      <label>Ciudad nuevos clientes</label>
                      <select
                        name="select"
                        class="form-control"
                        v-model="account.clients_city"
                      >
                        <option
                          v-for="(city, index) in cities"
                          v-bind:key="index"
                          :value="city['ID']"
                        >
                          {{ city['NOMBRE'] }}
                        </option>
                      </select>
                    </div>
                    <div class="col-lg-2">
                      <label>Serie clientes</label>
                      <input type="text" class="form-control" v-model="account.clients_key_serie">
                    </div>
                    <div class="col-lg-2">
                      <label>Folio clientes</label>
                      <input type="text" class="form-control" v-model="account.clients_key_folio">
                    </div>
                  </div>
                  <div class="row mt-4">
                      <div class="col-lg-2">
                        <base-button type="primary" v-on:click="updateAccount()"
                          >Guardar</base-button>
                      </div>
                    </div>
                </div>
                <hr class="my-4" />
                <!-- Reasons -->
                <h6 class="heading-small text-muted mb-4">
                  Razones de visita inefectiva
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div v-if="reasonAction != ''" class="col-md-8">
                      <base-input
                        alternative=""
                        label="Razón"
                        placeholder="Ingresa la razón"
                        input-classes="form-control-alternative"
                        v-model="reason.reason"
                      />
                    </div>
                    <div class="col-md-8">
                      <base-table :columns="reasonsColumns" :data="reasons">
                        <template slot-scope="{ row }">
                          <td class="budget">
                            {{ row.razon }}
                          </td>
                          <td class="text-left">
                            <base-dropdown class="dropdown" position="right">
                              <a
                                slot="title"
                                class="btn btn-sm btn-icon-only text-dark"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i class="fas fa-ellipsis-v"></i>
                              </a>

                              <template>
                                <span
                                  class="dropdown-item"
                                  v-on:click="deleteReason(row)"
                                  >Eliminar</span
                                >
                              </template>
                            </base-dropdown>
                          </td>
                        </template>
                      </base-table>
                    </div>
                    <div class="col text-center col-md-2">
                      <base-button
                        v-on:click="
                          () => {
                            reasonAction = 'new';
                          }
                        "
                        type="success"
                        >Agregar</base-button
                      >
                    </div>
                    <div
                      v-if="reasonAction != ''"
                      class="col text-center col-md-2"
                    >
                      <base-button type="primary" v-on:click="saveReason()"
                        >Guardar</base-button
                      >
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <!-- Reasons -->
                <h6 class="heading-small text-muted mb-4">
                  Filtrar productos por líneas
                </h6>
                <div class="pl-lg-4">
                  <lines-selection :lines="lines" v-on:alert="showAlert"></lines-selection>
                </div>
              </form>
            </template>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <base-alert
            ref="alert"
            :type="alert.type"
            :dismissible="true"
            :icon="alert.icon"
          >
            <template slot="text">{{ alert.message }}</template>
          </base-alert>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import httpMixin from "../../mixins/httpMixin";
import LinesSelection from './components/LinesSelection';

export default {
  name: "settings",
  data() {
    return {
      account: {
        id: 0,
        schedule: "",
        pricingList: 0,
        return_payment_method: 0,
        clients_payment_condition: 0,
        clients_city: 0,
      },
      dataRequests: [],
      priceLists: [],
      paymentMethods: [],
      paymentConditions: [],
      cities: [],
      lines: [],
      schedules: [
        { value: "*/15 * * * *", description: "15 minutos" },
        { value: "*/30 * * * *", description: "30 minutos" },
        { value: "0 * * * *", description: "Hora" },
        { value: "0 */2 * * *", description: "2 horas" },
        { value: "0 */6 * * *", description: "6 horas" },
        { value: "0 */12 * * *", description: "12 horas" },
      ],
      reasonsColumns: ["razon", "acciones"],
      reasons: [],
      reason: {
        id: 0,
        reason: "",
      },
      reasonAction: "",
      alert: {
        type: "default",
        message: "",
        icon: "",
      },
    };
  },
  mounted() {
    this.initPusher();
    this.initAccount();
    this.getReasons();
    this.initPaymentMethods();
  },
  beforeDestroy() {
    this.$pusher.unsubscribe(
      "private-" +
        this.$store.state.pusher.channel +
        "." +
        this.$store.state.user.id
    );
  },
  components: {
    LinesSelection
  },
  methods: {
    initPusher: async function () {
        const channel = await this.$pusher.subscribe(
            "private-" + this.$store.state.pusher.channel + '.' + this.$store.state.user.id
        );
        await channel.bind("dataresponse", ({ data }) => {
            this.getDataResponse(data);
        });

        this.initDataRequets()
    },
    initDataRequets: function() {
        this.requestData('lineas');
        this.requestData('listasPrecio');
        this.requestData('condicionesPago');
        this.requestData('ciudades');
    },
    initAccount: async function () {
      this.httpGet("/accounts")
        .then((res) => {
          this.account = res.data;
        })
        .catch((err) => {
          this.alert.type = "danger";
          this.alert.message = err.message;
          this.alert.icon = "ni ni-notification-70";
          this.$refs.alert.alertCreated();
        });
    },
    mapCities: function(cities) {
      this.cities = cities.map(city => {
        return {
          ID: city.CIUDAD_ID,
          NOMBRE: `${city.PAIS} | ${city.ESTADO} | ${city.CIUDAD}`,
        }
      }).sort((a, b) => {
        if(a.NOMBRE < b.NOMBRE) { return -1; }
        if(a.NOMBRE > b.NOMBRE) { return 1; }
        return 0;
      })
    },
    initPaymentMethods: function() {
      this.httpGet('/sync/get/formaspago')
        .then((res) => {
          this.paymentMethods =res.data;
        })
        .catch((err) => {
          this.alert.type = "danger";
          this.alert.message = err.message;
          this.alert.icon = "ni ni-notification-70";
          this.$refs.alert.alertCreated();
        });
    },
    requestData: async function(table) {
        const data = {
            table: table
        };
        await this.httpPost("/data/request", data)
            .then(res => {
                this.dataRequests.push({table: table, token: res.data.request_token})
            })
            .catch(err => {
                console.log(err)
            });
    },
    getDataResponse: async function(data) {
        this.httpGet("/data/response/" + data.token)
            .then(res => {
            let table = this.dataRequests.find(ele => ele.token == data.token);
            switch (table.table) {
                case 'lineas':
                    this.lines = res.data
                break;
                case 'listasPrecio':
                  this.priceLists = [
                    {
                      "ID": 0,
                      "NOMBRE": 'Respetar precio por cliente'
                    },
                    ...res.data
                  ]
                break;
                case 'condicionesPago':
                  this.paymentConditions = res.data
                break;
                case 'ciudades':
                  this.mapCities(res.data);
                break;
                default:
                break;
            }
            })
            .catch(err => {
                console.log(err)
            });
    },
    updateAccount: function () {
      this.httpPut("/accounts/settings", { account: this.account })
        .then((res) => {
          this.alert.type = "success";
          this.alert.message = "Información guardada correctamente";
          this.alert.icon = "ni ni-like-2";
          this.$refs.alert.alertCreated();
        })
        .catch((err) => {
          this.alert.type = "danger";
          this.alert.message = err.message;
          this.alert.icon = "ni ni-notification-70";
          this.$refs.alert.alertCreated();
        });
    },
    /*******Reasons methods *********/
    getReasons: function () {
      this.httpGet("/reasons")
        .then((res) => {
          this.reasons = res.data;
        })
        .catch((err) => {
          this.alert.type = "danger";
          this.alert.message = err.message;
          this.alert.icon = "ni ni-notification-70";
          this.$refs.alert.alertCreated();
        });
    },
    saveReason: function () {
      this.httpPost("/reasons", { reason: this.reason })
        .then((res) => {
          this.getReasons();
          this.alert.type = "success";
          this.alert.message = "Información guardada correctamente";
          this.alert.icon = "ni ni-like-2";
          this.$refs.alert.alertCreated();
          this.reason.reason = "";
          this.reason.id = 0;
          this.reasonAction = "";
        })
        .catch((err) => {
          this.alert.type = "danger";
          this.alert.message = err.message;
          this.alert.icon = "ni ni-notification-70";
          this.$refs.alert.alertCreated();
        });
    },
    deleteReason: function(reason) {
      this.httpPut("/reasons", { reason: reason })
        .then((res) => {
          this.getReasons();
          this.alert.type = "success";
          this.alert.message = "Información guardada correctamente";
          this.alert.icon = "ni ni-like-2";
          this.$refs.alert.alertCreated();
          this.reason.reason = "";
          this.reason.id = 0;
          this.reasonAction = "";
        })
        .catch((err) => {
          this.alert.type = "danger";
          this.alert.message = err.message;
          this.alert.icon = "ni ni-notification-70";
          this.$refs.alert.alertCreated();
        });
    },
    showAlert: function ({ status, err }) {
      if(status) {
          this.alert.type = "success";
          this.alert.message = "Información guardada correctamente";
          this.alert.icon = "ni ni-like-2";
          this.$refs.alert.alertCreated();
      } else {
        this.alert.type = "danger";
        this.alert.message = err.message;
        this.alert.icon = "ni ni-notification-70";
        this.$refs.alert.alertCreated();
      }
    }
  },  
  mixins: [httpMixin],
};
</script>
<style></style>
