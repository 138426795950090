<template>
    <section>
        <div class="row" style="max-height: 500px; overflow-y: scroll;">                    
            <div v-for="line in allLines" :key="line.id" class="col-md-12">            
                    <base-checkbox v-model="line.checked"><span >{{line.NOMBRE}}</span></base-checkbox>
            </div>
        </div>
        <div class="col-md-12 mt-3">
            <base-button type="primary" v-on:click="saveFilteredLines"
            >Guardar</base-button>
        </div>
    </section>
</template>
<script>
import httpMixin from "../../../mixins/httpMixin";
export default {
    name: 'lines-selection',
    props: {
        lines: {
            required: true,
            default: []
        }
    },
    data() {
        return {
            filterLines: [],
            allLines: [],
        }
    },
    mounted() {
        this.getFilterLines();
    },
    watch: {
        lines(newValue) {
           this.allLines = newValue.map(line => {
               if (this.filterLines.indexOf(line.ID) >= 0) {
                line.checked = true;
               }
               return line;
           });
        },
    },
    methods: {
        saveFilteredLines: function () {
            this.filterLines = this.allLines.filter(line => line.checked === true)
            const newFilterLines = this.filterLines.map(line => {return line.ID});
            const linesString = newFilterLines.join(',');
            console.log(linesString)
            this.httpPut("/accounts/lines", { lines: linesString })
                .then((res) => {                
                    this.$emit('alert', {status: true})
                })
                .catch((err) => {
                    this.$emit('alert', {status: false, err: err})
                });
        },
        getFilterLines: function () {
            this.httpGet("/accounts")
                .then((res) => {
                    this.filterLines = res.data.filter_lines.split(',');
                    this.filterLines = this.filterLines.map(function (line) {                        
                        return parseInt(line, 10)
                    })
                })
                .catch((err) => {
                
                });
        }
    },
    mixins: [httpMixin]
}
</script>