<template>
    <div>
        <base-header type="gradient-blue" class="pb-6 pb-8 pt-5 pt-md-5">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <transactions-table title="Transacciones"></transactions-table> 
                </div>
            </div>
        </div>

    </div>
</template>
<script> 
  import TransactionsTable from './Tables/TransactionsTable'
  export default {
    data() {
      return {
      }
    },
    components: {
      TransactionsTable
    }
  };
</script>